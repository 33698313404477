export const level_one = [
  ["a", "s", "d", "f"],
  ["j", "k", "l", ";"],
  ["e", "i"],
  ["g", "h"],
  ["r", "u"],
  ["t", "y"],
  ["q", "w", "o", "p"],
  ["v", "b", "m", "n"],
  ["z", "x", "c"],
  [".", ","],
];

export const level_two = [
  ["a", "abandon", "ability", "able", "about", "above", "abroad", "absence", "absolute", "abstract", "abuse", "academic", "accede", "accept", "access", "accident", "accolade", "accompany", "accomplish", "accord", "account", "accurate", "accuse", "ache", "achieve", "acknowledge", "acquire", "acquit", "acronym", "across", "action", "action", "active", "activity", "actor", "actress", "actual", "actually", "ad", "adapt", "add", "addiction", "addition", "additional", "address", "adequate", "adjourn", "adjust", "administration", "admiration", "admire", "admission", "admit", "adopt",  "adoption",  "adult",  "advance",  "advantage",  "adventure",  "adventurous",  "advertise",  "advertisement",  "advice",  "advise",  "affair",  "affect",  "affection",  "affectionate",  "afford",  "afraid",  "after",  "afternoon",  "again",  "against",  "age",  "aged", "agency", "agenda", "agent", "aggravation", "aggressive", "ago", "agree", "agreement", "antihistamine", "antonym", "anxiety", "anxious", "any", "anybody", "anymore", "anyone", "anything", "anyway", "anywhere", "apart", "apartment", "apologize", "apology", "app", "apparent", "apparently", "appeal", "appear", "appearance", "appetite", "apple", "application", "apply", "appointment", "appreciate", "approach", "appropriate", "approval"] ,
  ["sacrifice", "sad", "sadness", "safe", "safety", "sail", "sake", "salad", "salary", "sale", "salesman", "salt", "same", "sample", "sand", "sandwich", "sarcastic", "satisfaction", "satisfy", "sauce", "save", "savings", "say", "scale", "scalp", "scare", "scare", "scene", "scenery", "schedule", "schedule", "scheme", "school", "science", "scientific", "scientist", "scissors", "scope", "score", "score", "scoreboard", "scorn", "scratch", "scream", "screen", "screw", "scrutinize", "sea", "seal", "shade", "shadow", "shake", "shall", "shallow", "shame", "shape", "share", "sharp", "shave", "she", "sheep", "sheet", "shelf", "shell", "shelter", "shift", "shin", "shine", "ship", "shirt", "shock", "silly", "silver", "silver", "similar", "simper", "simple", "sin", "since", "sincere", "sing", "singer", "single", "singles", "sink", "sir", "sister", "sit", "site", "situation", "size", "skill", "skin", "skirt", "sky", "slang", "slap", "slow", "small", "smart", "smell", "smile", "smog", "smoke", "smooth", "snake", "sneeze", "snow", "so"],
  ["label", "labor", "laboratory", "lack", "ladder", "lady", "lake", "lamp", "land", "landlord", "lane", "language", "large", "last", "late", "latter", "laugh", "lavatory", "law", "lawyer", "lay", "layer", "layoff", "lazy", "lead", "leader", "leaf", "league", "leak", "lean", "learn", "least", "leather", "leave", "lecture", "left", "leg", "legal", "lemon", "lend", "length", "less", "lesson", "let", "letter", "level", "liberal", "liberty", "library", "license", "lid", "lie", "life", "lift", "light", "load", "loaf", "loan", "local", "locate", "location", "lock", "locker", "logic", "logical", "loneliness", "lonely", "long", "longing", "look", "loose", "lord", "lose", "loss", "lost", "lot", "loud", "love", "lovely", "low", "lower", "loyal", "luck", "lucky", "ludicrous", "luggage", "lump", "lunch", "lung", "lust", "lightning", "like", "likely", "limit", "line", "link", "lip", "liquid", "list", "listen", "literary", "literature", "litigate", "little", "little", "live", "live"],
  ["baby", "bachelor", "back", "backboard", "background", "backhand", "backup", "backward", "bacterium", "bad", "bag", "baggage", "bake", "balance", "ball", "band", "bandage", "bane", "bang", "bank", "banker", "bankrupt", "banter", "bar", "barber", "bare", "bedroom", "bee", "beef", "beer", "before", "beg", "begin", "beginning", "behalf", "behave", "behavior", "behind", "being", "belie", "belief", "believe", "bell", "belong", "below", "belt", "bench", "bend", "beneath", "benefit", "beside", "besides", "best", "bestow", "bet", "better", "between", "beyond", "bible", "bibliography", "bicycle", "bid", "big", "bill", "billion", "bind", "biodegradable", "biodiversity", "biology", "bird", "birth", "birthday", "bit", "bite", "bitter", "black", "blackout", "blade", "blame", "bland", "blank", "blanket", "bleed", "blend", "blind", "blizzard", "bottle", "bottom", "bounce", "bound", "boundary", "bow", "bowl", "box", "boy", "boyfriend", "brace", "brain", "branch", "brand", "brave", "bread", "break", "breakfast", "breast", "breath", "breathe", "breed", "breed", "breezy", "bribe", "bride", "bridge", "brief", "bright"],
  ["wage", "waist", "wait", "waive", "wake", "walk", "wall", "wallet", "wander", "want", "war", "warm", "warning", "wash", "waste", "watch", "water", "wave", "wax", "way", "we", "weak", "weakness", "wealth", "weapon", "wear", "weather", "weave", "web", "wedding", "weed", "week", "weekend", "weekly", "weigh", "weight", "weird", "welcome", "welfare", "well", "west", "western", "wet", "wetland", "what", "whatever", "wheat", "wheel", "when", "where", "wherever", "whether", "which", "while", "whisper", "whistle", "white", "who", "whole", "whom", "whose", "why", "wide", "widely", "widespread", "width", "wife", "wild", "wildlife", "will", "willing", "win", "wind", "windchill", "windmill", "window", "windy", "wine", "wing", "winner", "winter", "wipe", "wisdom", "wise", "wish", "with", "withdraw", "within", "without", "witness", "witty", "wolf", "woman", "wonder", "wonderful", "wood", "wooden", "wool", "word", "work", "worker", "workout", "world", "worldwide", "worry", "worse", "worship", "worst", "worth", "worthy", "would", "wound", "wrap", "wrath", "wreck", "wrinkle", "wrist", "write", "writer", "writing", "wrong"]
];

export const level_three = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "+", "-", "*", "/", "."],
];